import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
	max-width: 800px;

  ${breakpoint.medium`
  	max-width: 1000px;
  `}
`

const StyledMessage = styled.section`
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 80px 0;
  `}

	p {
		margin: 0 0 1em 1em;
	}

  a {
    color: ${colors.orange};

    &:hover,
    &:active {
      color: ${colors.purpleDark};
    }
  }
`

const Message = props => {
	return (
		<StyledMessage>
			<StyledContainer>
				<p>
					Dear Valued Customer,
				</p>
				<p>
					We want to inform you that
					the Visby Medical Sexual Health Click Test is no longer available. We have
					introduced the <Link to="/sexual-health-test/">Visby Medical Sexual Health Test</Link>, which brings significant improvements in
					workflow, manufacturability, and reliability.
				</p>
				<p>
					If you have any questions
					regarding the Visby Medical Sexual Health Click Test or would like to order the
					new Visby Medical Sexual Health Test, please don't hesitate to reach out to our
					dedicated customer support team at <a href="tel:+1833-468-4729">1-833-GoVisby</a> (1-833-468-4729). We appreciate your continued support and look forward to
					serving you.
				</p>
				<p>
					Best regards,<br />
					Visby Medical Team
				</p>
			</StyledContainer>
		</StyledMessage>
	)
}
export default Message