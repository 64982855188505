import React from "react"

// Components
import Seo from "components/seo"
import Hero from "./../components/hero"

// Page Sections
import Message from "./screens/poc-downloads/message"

const PocDownloads = () => {
	return (
		<React.Fragment>
			<Hero pageTitle="Point of Care Downloads">
				<p>
					Download point of care resources for our Visby Medical products.
				</p>
			</Hero>
			<Message />
		</React.Fragment>
	)
}

export default PocDownloads

export const Head = () => (
  <Seo
		title="Point of Care Downloads"
		description="Download point of care resources for our Visby Medical products."
  />
)
